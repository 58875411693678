/* Import the Manrope and DM Sans fonts from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=DM+Sans:wght@400;500;700&display=swap");

/* Basic resets and styling */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
}

body {
  color: #333;
  overflow-x: hidden;
}

/* Basic resets and styling */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
}

body {
  color: #333;
  overflow-x: hidden;
}

/* Header and Navbar */
/* Header and Navbar */
/* Header and Navbar */
.header {
  align-items: center;
  animation: gradientShift 15s ease infinite;
  background: linear-gradient(135deg, #232323e6, #3a3a3ae6 50%, #232323e6),
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Cdefs%3E%3Cpattern id='a' width='10' height='10' patternUnits='userSpaceOnUse'%3E%3Cpath d='M10 0H0v10' fill='none' stroke='%23fff' stroke-width='.5'/%3E%3C/pattern%3E%3Cpattern id='b' width='10' height='10' patternUnits='userSpaceOnUse' patternTransform='rotate(45)'%3E%3Cpath d='m5 0 5 5-5 5-5-5Z' fill='none' stroke='%23fff' stroke-width='.5'/%3E%3C/pattern%3E%3C/defs%3E%3Cpath fill='url(%23a)' d='M0 0h100v100H0z'/%3E%3Cpath fill='url(%23b)' d='M0 0h100v100H0z'/%3E%3C/svg%3E");
  background-blend-mode: overlay;
  background-size: 200% 200%, 100px 100px;
  border-bottom: 1px solid #0003;
  box-shadow: inset 0 0 30px #00000080;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 80vh;
  padding: 4rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1000;
}

.typing-container {
  font-size: 2rem;
  font-family: "Courier New", Courier, monospace;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid #fff; /* Cursor effect */
  width: 0;
  animation: typing 4s steps(30, end), blink-caret 0.75s step-end infinite;
}

.header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: inherit; /* Inherit the background from .header */
  animation: gradientShift 15s ease infinite;
}

/* Other CSS classes remain unchanged */

.header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: inherit; /* Inherit the background from .header */
  animation: gradientShift 15s ease infinite;
}

.App {
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 2rem;
  z-index: 1001;
}

.navbar .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

/* .menu-toggle {
  display: none;
} */

.menu-label {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 2rem;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: #2b2b2b;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Header Content */
.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  color: #333;
}

.profile-image {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  object-fit: cover;
  margin-bottom: 1.5rem;
  border: 5px solid #555555;
}

.header-info h1 {
  font-size: 3rem;
  margin: 0.5rem 0;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #e2dede;
  position: relative;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header-info h1::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #007bff, #00d084);
  margin: 1rem auto 18px;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.header-info .title {
  font-size: 1.5rem;
  color: #666;
  font-family: "Manrope", sans-serif;
  margin-top: 0.5rem;
}

.header-info .summary {
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  color: gray;
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Main Sections */
.main {
  padding: 2rem;
}

.section {
  background: linear-gradient(135deg, #ffffff 0%, #f7f7f7 100%);
  padding: 4rem 2rem;
  margin: 1rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #333;
  font-family: "Manrope", sans-serif;
  position: relative;
  text-align: center;
}

.section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 5px;
  background: linear-gradient(90deg, #007bff, #00d084);
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

.skills-grid,
.education-list,
.experience-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skill-card,
.education-item,
.experience-item {
  flex: 1;
  min-width: 200px;
  padding: 1.5rem;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  margin: 0;
}

.icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.contact-links {
  background-color: #ffffff; /* Consistent design with the rest of the site */
  border: 1px solid #e0e0e0;
  padding: 2rem;
  text-align: left;
  margin: 2rem 0;
}

.contact-links:hover {
  background-color: #f7f7f7;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.contact-links p {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: center;
}

.contact-icon {
  font-size: 1.5rem;
  color: #007bff;
  margin-right: 0.75rem;
}

.contact-links a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
}

.contact-links a:hover {
  text-decoration: underline;
}

/* Footer */
.footer {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid #e0e0e0;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0.5rem 0;
}

.footer-links a {
  color: #007bff;
  font-size: 1.5rem;
  text-decoration: none;
}

.footer-links a:hover {
  color: #0056b3;
}

/* Scroll Effect for Navbar */
body.scrolled .navbar {
  background-color: #1f1f1f;
}

body.scrolled .nav-links a {
  color: #fff;
}

/* Contact Section */
.contact-links {
  background-color: #ffffff; /* Consistent design with the rest of the site */
  padding: 2rem;
}

.contact-links p {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.contact-icon {
  margin-right: 0.5rem;
}

.contact-links a {
  color: #000;
  text-decoration: none;
}

.contact-links a:hover {
  text-decoration: underline;
}

.cta-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
}

.cta-button:hover {
  background-color: #333;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .main {
    padding: 0rem;
  }
  .contact-links {
    text-align: center;
  }

  .contact-links p {
    flex-direction: column;
    align-items: center;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .contact-links {
    text-align: center;
  }

  .contact-links p {
    flex-direction: column;
    align-items: center;
  }
}

/* Mobile Menu Toggle and Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-label {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .nav-links a {
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .nav-links a:hover {
    background-color: #f0f0f0;
  }

  .menu-toggle:checked + .nav-links {
    display: flex;
  }
}
/* Header and Navbar */

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  color: #333;
}

.profile-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1.5rem;
  border: 5px solid #555555;
}

.header-info h1 {
  font-size: 3rem;
  margin: 0.5rem 0;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #e9e9e9;
  position: relative;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header-info h1::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #007bff, #00d084);
  margin: 1rem auto 18px;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.header-info .title {
  font-size: 1.5rem;
  color: #f1f1f1;
  font-family: "Manrope", sans-serif;
  margin-top: 0.5rem;
}

.header-info .summary {
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Mobile Menu Toggle and Styles */
@media (max-width: 768px) {
  .profile-image {
    border-radius: 50%;
  }
  .summary {
    display: none;
  }
  .header-info h1 {
    font-size: 2rem;
  }

  .navbar {
    padding: 1rem;
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-label {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .nav-links a {
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .nav-links a:hover {
    background-color: #f0f0f0;
  }

  .menu-toggle:checked + .nav-links {
    display: flex;
  }
}
